<template>
	<div class="dian-documentid">
		<a :href="document.link" target="_blank" class="link" :class="document.status == 500 ? 'fail': 'success'" :title="document.status == 500 ? 'Documento con errores de validación' : 'Documento aceptado'">
			{{ document.id }}
		</a>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';

import invoice from '@/modules/dian/img/invoice.png';
import debitnote from '@/modules/dian/img/debitnote.png';
import creditnote from '@/modules/dian/img/creditnote.png';

export default {
	name: 'dian-documentid',
	mixins: [useI18n, useApi],

	props: {
		document: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			invoice,
			debitnote,
			creditnote,
		};
	},

	watch: {
		document: {
			immediate: true,
			handler() {},
		},
	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.link {
	text-decoration: none;
	color: black;
	font-weight: normal;
}
.success {
	color: inherit;
}
.fail {
	color: red;
}
</style>
