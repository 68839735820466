<template>
	<div class="protecdata-document">
		<div v-for="(document, i) in innerData" :key="i">
            <ui-item
                :text="document.name"
                :secondary="document.moduleName"
                :tertiary="document.description"
                class="document"
            ></ui-item>
		</div>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';

import { UiItem } from '@/modules/ui/components';

export default {
	name: 'protecdata-document',
    mixins: [useI18n, useApi],
    components: { UiItem },
	props: {
		data: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
            innerData: JSON.parse(this.data),
        };
    },
    
    mounted() {
        console.log(this.innerData)
    },

	methods: {},
};
</script>

<style lang="scss">
.ui-icon-image {
    width: 32px !important;
    height: 32px !important;
}
</style>
