<template>
	<div class="protecdata-file">
		<div class="link">
			<div class="protedata_pdf">
				<img
					:src="pdf"
					width="40"
					@click="openDialog(link.url, link.title)"
				/>
			</div>
		</div>
		<ui-dialog :title="dialogTitle" :close-scrim="true" :open.sync="showDialog">
			<template #contents>
				<iframe
					:src="src"
					frameborder="0"
					width="810px"
					height="600px"
				></iframe>
			</template>
		</ui-dialog>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';
import { UiDialog } from '@/modules/ui/components';
import pdf from '@/modules/protecdata/img/e-pdf.png';

export default {
	name: 'protecdata-file',
	mixins: [useI18n, useApi],
	components: { UiDialog },
	props: {
		link: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			pdf,
			showDialog: false,
			dialogTitle: null,
			src: null,
		};
	},

	methods: {
		openDialog(link, title) {
			this.dialogTitle = title;
			this.src = link;
			this.showDialog = true;
		},
	},
};
</script>

<style lang="scss">
.ui-dialog .ui-dialog-container {
	width: auto !important;
}
.link {
	display: flex;
	flex-direction: row;
}
.protedata_pdf {
	flex-grow: 1;
	align-items: flex-start;
	justify-content: center;
    margin-left: 5px;
}
.protedata_pdf:hover {
	cursor: pointer;
}
</style>
