<template>
	<div class="signio-document">
        <ui-item
            :text="innerData.Transaccion"
            :secondary="dataFileName"
            class="document"
        ></ui-item>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';

import { UiItem } from '@/modules/ui/components';

export default {
	name: 'signio-document',
    mixins: [useI18n, useApi],
    components: { UiItem },
	props: {
		data: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
            innerData: JSON.parse(this.data),
        };
    },

    computed: {
        dataFileName() {           
            let documentId = this.innerData.documentId;
            let result = this.innerData.Documentos.filter((el) => {
                return el.id ==  documentId;
            });
            return result[0].nombre;
        }
    },

	methods: {},
};
</script>

<style lang="scss">
.ui-icon-image {
    width: 32px !important;
    height: 32px !important;
}
</style>
