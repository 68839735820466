<template>
	<div class="dian-links">
		<div class="link">
			<div class="xml">
				<a v-if="links.xml && !links.canceled && links.status != 500" :href="links.xml" target="_blank">
					<img :src="xml" width="18" />
				</a>
			</div>
			<div class="pdf" v-if="!links.canceled && links.status != 500">
				<img
					v-if="links.pdf"
					:src="pdf"
					width="18"
					@click="openDialog(links.documentId, links.pdf, 'Documento electrónico')"
				/>
			</div>
			<div class="email">
				<img
					v-if="links.email && !links.canceled && links.status != 500"
					:src="email"
					width="14"
					@click="
						openDialog(
							links.documentId,
							links.email,
							'Reporte envío de documento electrónico a Correo'
						)
					"
				/>
			</div>
		</div>
		<ui-dialog :title="dialogTitle" :close-scrim="true" :open.sync="showDialog">
			<template #contents>
				<iframe
					:src="src"
					frameborder="0"
					width="810px"
					height="600px"
				></iframe>
			</template>
		</ui-dialog>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';
import { UiDialog } from '@/modules/ui/components';
import pdf from '@/modules/dian/img/pdf_dian.png';
import xml from '@/modules/dian/img/xml_dian.png';
import email from '@/modules/dian/img/dian-send-document.png';

export default {
	name: 'dian-links',
	mixins: [useI18n, useApi],
	components: { UiDialog },
	props: {
		links: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			pdf,
			xml,
			email,
			showDialog: false,
			dialogTitle: null,
			src: null,
		};
	},

	methods: {
		openDialog(id, link, title) {
			this.dialogTitle = title;
			this.src = link;
			this.showDialog = true;
		},
	},
};
</script>

<style lang="scss">
.ui-dialog .ui-dialog-container {
	width: auto !important;
}
.link {
	display: flex;
	flex-direction: row;
}
.xml {
	flex-grow: 1;
	align-items: flex-start;
	justify-content: center;
}
.pdf {
	flex-grow: 1;
	align-items: flex-start;
	justify-content: center;
    margin-left: 5px;
}
.email {
	flex-grow: 1;
	align-items: center;
	justify-content: center;
    padding-top: 5px;
    margin-left: 5px;
}
.xml:hover,
.pdf:hover,
.email:hover {
	cursor: pointer;
}
</style>
