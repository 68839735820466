var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dian-sequence"},[_c('span',{class:[
			_vm.dian.clientResponse == 1
				? 'accepted'
				: _vm.dian.clientResponse == 0
				? 'rejected'
				: null,
			,
			_vm.dian.canceled ? 'canceled' : null ]},[_c('span',{staticStyle:{"display":"inline-flex","padding-right":"3px"},attrs:{"title":_vm.dian.canceled ? 'Documento anulada' : (_vm.dian.status == 500 ? 'Documento rechazado' : 'Documento vigente')}},[_vm._v(" "+_vm._s(_vm.dian.sequence)+" "),(_vm.dian.canceled)?_c('div',{staticStyle:{"position":"relative","left":"7px","top":"0px"}},[_c('img',{staticClass:"external",attrs:{"src":_vm.exclamation,"width":"14"}})]):_vm._e(),(_vm.dian.status == 500)?_c('div',[_c('span',{staticClass:"messageError"},[_vm._v("rechazado")])]):_vm._e()])]),(!_vm.dian.canceled && _vm.dian.status != 500)?_c('a',{attrs:{"href":_vm.dian.link,"target":"_blank"}},[_c('img',{attrs:{"src":_vm.dianSuccess,"title":[("Revisar documento " + (this.dian.sequence) + " en la DIAN")],"width":"12"}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }